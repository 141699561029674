<template>
  <div>
    <v-card-text :style="`height: ${height}px; overflow: auto`" class="pa-0">
      <a-table-f-api
        ref="table"
        :api="api"
        :model="model"
        :searchable="false"
        :useQuery="false"
        :defaults="{
          sort1: { key: 'id', order: 'DESC' },
          filters: {
            education_chain_id: data.id,
          },
          paramName: 'education.messages',
        }"
        @click="onClick($event)"
        @loadData="messages = $event"
      >
        <template v-slot:item.actions1="{ row }">
          <v-btn x-small fab class="ma-0" color="green" title="Акт" @click.stop="showEditDialogFun(row.id)">
            <v-icon small>fas fa-pen</v-icon>
          </v-btn>
        </template>
        <template v-slot:top>
          <v-fab-transition v-if="canAdd">
            <v-btn fab dark color="green" absolute bottom small style="bottom: 15px" left class="v-btn--example" @click="createMessage()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-fab-transition>
        </template>
      </a-table-f-api>
    </v-card-text>
    <portal to="v-main">
      <MessageEdit
        v-model="showEditDialog"
        v-if="showEditDialog"
        :api="api"
        :m="m"
        :id="idEdit"
        :data="data"
        :init-data="messageInitData"
        :messages="messages"
        @createMessage="createMessage($event)"
      />
      <s-edit-form
        ref="messageDialog"
        v-model="showEditDialog"
        v-if="showEditDialog && false"
        :api="api"
        :m="m"
        :id="idEdit"
        :configForm="{ type: 'default' }"
        :init-data="messageInitData"
        :opt="{ closeAfterSave: !true, delete: true }"
      >
        <template v-slot:form>
          <v-row no-gutters style="flex-wrap: nowrap" class="d-flex pb-2">
            <v-switch
              v-model="isInitMessage"
              dense
              hide-details
              :label="`Сообщение, начинающее диалог`"
              color="indigo"
              class="col-sm-4 col-12"
              @change="$refs.messageDialog.validate('next_id')"
            ></v-switch>
            <v-btn v-if="message && message.from_id" :disabled="$refs.messageDialog.isChanged" class="col-sm-2 col-12" @click="gotoMessage(message.from_id)">
              Пред. ({{ message.from_id }})
            </v-btn>
            <v-btn v-if="message && message.next_id" :disabled="$refs.messageDialog.isChanged" class="col-sm-2 col-12" @click="gotoMessage(message.next_id)">
              След. ({{ message.next_id }})
            </v-btn>
          </v-row>
          <v-row no-gutters style="flex-wrap: nowrap" class="d-flex pb-2">
            <v-select
              class="col-sm-10 col-12"
              v-if="message"
              v-model="message.next_id"
              :items="messages.filter((el) => el?.id !== message.id)"
              item-text="content"
              item-value="id"
              dense
              outlined
              clearable
              label="Следующее сообщение"
              @change="$refs.messageDialog.validate('next_id')"
            >
            </v-select>

            <v-btn v-if="message && !message.next_id" :disabled1="$refs.messageDialog.isChanged" class="col-sm-2 col-12" @click="createMessage(message.id)">
              Создать
            </v-btn>
          </v-row>
        </template>
      </s-edit-form>
    </portal>
  </div>
</template>

<script>
//import MessageButton from "./components/buttons";
import { getAccess, popupMenu, genModel } from "@/components/mixings";
export default {
  mixins: [getAccess, popupMenu, genModel],
  components: {
    MessageEdit: () => import("./../dialogs/messageEdit"),
  },
  props: {
    height: { type: Number, default: 300 },
    data: Object,
  },
  data() {
    return {
      showEditDialog: false,
      messages: [],
      idEdit: 0,
      m: this.$store.getters["config/get"].models.education.message,
      messageInitData: null,
    };
  },
  created() {},
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData(this.idEdit);
        this.idEdit = 0;
        this.messageInitData = null;
      }
    },
  },
  computed: {
    message() {
      if (!this.idEdit) return null;
      return this.$refs.messageDialog.data;
    },
    isInitMessage: {
      get() {
        if (this.message) {
          let message = this.message;
          if (message.id == this.data.education_message_id || message.form_id == -1) return true;
        }
        return false;
      },
      set(v) {
        let message = this.$refs.messageDialog.data;
        if (v) message["from_id"] = -1;
      },
    },
    api() {
      return this.m.api;
    },
    model() {
      let model = this.getModelList(this.m);
      return model;
    },
    canAdd() {
      return true;
    },
  },
  methods: {
    createMessage(from = 0) {
      console.log("create message");
      this.showEditDialog = !true;
      this.idEdit = 0;

      this.messageInitData = { education_chain_id: this.data.id, from_id: from };
      this.showEditDialog = true;
    },
    gotoMessage(id) {
      this.showEditDialog = !true;
      this.idEdit = id;
      this.showEditDialog = true;
    },
    onClick(d) {
      this.idEdit = d.row.id;
      this.showEditDialog = true;
    },
  },
};
</script>
